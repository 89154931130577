import Tag from './Tag';

class TagCollection {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(name, tags = []) {
    this.name = name;
    this.tags = tags;
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  addTag(tag) {
    if (this.hasTag(tag)) {
      return;
    }

    this.tags.push(tag);
  }

  hasTag(tag) {
    return !!this.getTag(tagName);
  }

  getTag(tagName) {
    return this.tags.find(t => t.name === tagName);
  }
}

export const TAG_DONE = new Tag('done');

export const DefaultTagCollection = new TagCollection('default', [TAG_DONE]);

export default TagCollection;
import md5 from 'md5';
import { randomId} from '~/utils/IdUtil';
import { globalState } from '~/store/global';
import UserSettings from './UserSettings';

export default class User {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(id, settings = undefined) {
    this.id = id || randomId();
    this.userSettings = settings || new UserSettings();
  }

  //---------------------------------------------------------------------------
  // Saving/Loading
  //---------------------------------------------------------------------------
  async save() {
    try {
      const json = this.toJSON();
      const newContent = JSON.stringify(json);
      const prevContent = localStorage.getItem('user');

      const newMD5 = md5(newContent);
      const prevMD5 = md5(prevContent || '');

      if (newMD5 !== prevMD5) {
        console.log('Saving User', json);
        localStorage.setItem('user', newContent);
      }
    } catch(error) {
      console.error('Erorr saving USER to local storage', error);
    } 
  }

  static async load() {
    console.log('Loading USER from local storage...');

    const prevUser = localStorage.getItem("user");

    let user;

    if (prevUser && prevUser.length > 1) {
      user = User.fromJSON(JSON.parse(prevUser));
    } else {
      user = User.default();
    }

    globalState.user = user;

    return user;
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    return new User(json.id, UserSettings.fromJSON(json.userSettings));
  }

  toJSON() {
    return {
      id: this.id,
      userSettings: this.userSettings.toJSON()
    }
  }

  static default() {
    return new User();
  }
}
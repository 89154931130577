export function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function isDescendentOfDomNodeNames(element, names) {
  while (element) {
    if (names.indexOf(element.nodeName.toLowerCase()) !== -1) {
      return true;
    }

    element = element.parentNode;
  }

  return false;
}

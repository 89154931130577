export default class UserSettings {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(ui) {
    this.ui = ui || {
      globalTimeFilter: undefined,
      showTimeline: false
    }
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    const ui = {
      globalTimeFilter: json.timeFilter ? TimeFilter.fromJSON(json.timeFilter) : undefined
    }
    return new UserSettings(ui);
  }

  toJSON() {
    return {
      ui: {
        globalTimeFilter: this.ui.globalTimeFilter ? this.ui.globalTimeFilter.toJSON() : undefined
      }
    }
  }
}

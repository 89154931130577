<template>
  <div :class="dropDestinationClass"
       @mousemove="self_mousemoveHandler"
       @mouseout="self_mouseoutHandler"
       @mouseup="self_mouseupHandler"
       ref="self">
    <slot />
  </div>
</template>

<script>
import './DropDestination.scss';

export default {
  props: {
    dropPointsAllowed: {
      type: Array,
      default: () => ['center']
    }
  },
  data() {
    return {
      highlightedDropPoint: undefined,
      dropDisallowed: false
    }
  },
  computed: {
    dropDestinationClass() {
      return this.highlightedDropPoint ? `drop-destination highlight ${this.highlightedDropPoint.name}` : 'drop-destination';
    }
  },
  methods: {
    calculateEdgeDropPoints() {
      const rect = this.$refs.self.getBoundingClientRect();
      return [
        { name: 'topleft', x: 0, y: 0},
        { name: 'topright', x: rect.width, y: 0},
        { name: 'bottomright', x: rect.width, y: rect.height},
        { name: 'bottomleft', x: 0, y: rect.height},
        { name: 'top', x: rect.width / 2, y: 0},
        { name: 'right', x: rect.width, y:  rect.height / 2},
        { name: 'bottom', x: rect.width / 2, y:  rect.height},
        { name: 'left', x: 0, y:  rect.height / 2},
        { name: 'center', x: rect.width / 2, y:  rect.height / 2},
      ];
    },
    calculateClosestPoint(point, points) {
      points.forEach(p => {
        const xdelta = p.x - point.x;
        const ydelta = p.y - point.y;
        p.distance = Math.sqrt(xdelta * xdelta + ydelta * ydelta);
      });

      points.sort((a, b) => a.distance - b.distance);

      return points[0];
    },
    getBoundingRect() {
      return this.$refs.self.getBoundingClientRect();
    },
    getMousePointInClient(mouseEvent) {
      const rect = this.getBoundingRect();
      const x = mouseEvent.clientX - rect.left;
      const y = mouseEvent.clientY - rect.top;
      return { x, y };
    },
    calculateDropPointInSelf(mouseEvent) {
      const dropPointsEnabled = this.calculateEdgeDropPoints().filter(dp => this.dropPointsAllowed.indexOf(dp.name) !== -1);
      const mousePoint = this.getMousePointInClient(mouseEvent);

      return this.calculateClosestPoint(mousePoint, dropPointsEnabled);
    },
    self_mouseoutHandler(event) {
      this.highlightedDropPoint = undefined;
      this.dropDisallowed = false;
    },
    self_mousemoveHandler(event) {
      if (this.$store.state.dragAndDrop.isDragging) {
        const dropPoint = this.calculateDropPointInSelf(event);
        const data = this.$store.state.dragAndDrop.dragData;

        this.$emit('dropIsAccepted', {
          event,
          dropPoint,
          data,
          callback: (isAccepted) => {
            if (isAccepted) {
              this.highlightedDropPoint = dropPoint;
              this.dropDisallowed = false;
            } else {
              this.dropDisallowed = true;
            }
          }
        });
      } else {
        this.dropDisallowed = false;
        this.highlightedDropPoint = undefined;
      }
    },
    self_mouseupHandler(event) {
      if (this.$store.state.dragAndDrop.isDragging) {
        event.stopPropagation();
        event.preventDefault();

        if (this.highlightedDropPoint) {
          const dropPoint = this.calculateDropPointInSelf(event);
          const data = this.$store.state.dragAndDrop.dragData;

          this.$emit('drop', {dropPoint, data});
        }

        this.dropDisallowed = false;
        this.highlightedDropPoint = undefined;

        this.$store.commit('dragAndDrop/stopDrag');
      }
    }
  }
}
</script>

<template>
  <button class="link" @click="taskNode_clickHandler">{{taskNode.name}}</button>
</template>
<script>
export default {
  props: {
    taskNode: {
      type: Object,
      default: undefined
    }
  },
  methods: {
    taskNode_clickHandler(event) {
      this.taskNode.taskBoard.select(this.taskNode);
      this.taskNode.taskBoard.scrollTo(this.taskNode);
    }
  }
};
</script>

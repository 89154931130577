import TaskBoard from './TaskBoard';
import TaskNode from './tasks/TaskNode';
import md5 from 'md5';

import { globalState } from '~/store/global';

const default_ui = {
  workspaceControlsOpen: false,
  showArchived: false,
  showChartsModal: false,
  compact: false,
  backgroundUrl: '/default-background.jpg'
};

export const DEFAULT_TASK_TREE_ROOT = new TaskNode('root', undefined, [
  new TaskNode('Instructions'),
  new TaskNode('This is your primary task board in your workspace and these are tasks'),
  new TaskNode('Click a task name to edit (click here)'),
  new TaskNode('Hover your mouse over a task to see controls'),
  new TaskNode('Click the gear on the right of a task to open a task details', undefined, [
    new TaskNode('Click +15m or -15m to adjust the time on a task'),
    new TaskNode('Click trash can to archive a task'),
    new TaskNode('Click the play/stop button or the header of a task to start/stop it'),
    new TaskNode('When a task has time on it, you can see full timeline of that task in the details'),
    new TaskNode('Click Done! on a task that has time to mark it completed and Reopen to reopen it.')
  ]),
  new TaskNode('Add a new root task by clicking + Task in the header'),
  new TaskNode('Add a child task by clicking + in the header of the task'),
  new TaskNode('You can adjust workspace settings by clicking the large gear in the page header', undefined, [
    new TaskNode('Show archived will show the tasks you have archived'),
    new TaskNode('Show compact will show a smaller workspace'),
    new TaskNode('To change the workspace background, put a valid image url in the field'),
    new TaskNode('Click Reset Board to completely erase the board and start from scratch. You can do this when you are done with the instructions.')
  ]),
  new TaskNode('Drag the header of a task to move it around'),
  new TaskNode('The timeline page footer shows all your work for the time period you select'),
  new TaskNode('Search tasks in the main header. You can try it now!'),
  new TaskNode('Click the Pie Chart icon to view an outline of all time spent on tasks'),
  new TaskNode('Play around, have fun!')
]);

export default class Workspace {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(name) {
    this.name = name || 'Default Workspace';
    this.taskBoards = [];
    this.ui = default_ui;
    this.curBoardId = undefined;
  }

  //---------------------------------------------------------------------------
  // Properties
  //---------------------------------------------------------------------------
  get curTaskBoard() {
    return this.taskBoards.find(b => b.id === this.curBoardId);
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  setCurBoard(board) {
    this.curBoardId = board.id;
  }

  createBoard(name) {
    const board = new TaskBoard(name, undefined, this);

    this.taskBoards.push(board);

    return board;
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    const workspace = new Workspace(json.name);
    workspace.ui = json.ui;
    workspace.curBoardId = json.curBoardId;
    workspace.taskBoards = json.taskBoards.map(TaskBoard.fromJSON);

    workspace.taskBoards.forEach(tb => tb.workspace = workspace);

    return workspace;
  }

  toJSON() {
    return {
      name: this.name,
      ui: this.ui,
      curBoardId: this.curBoardId,
      taskBoards: this.taskBoards.map(tb => tb.toJSON())
    }
  }

  //---------------------------------------------------------------------------
  // Saving/Loading
  //---------------------------------------------------------------------------
  async save() {
    try {
      const json = this.toJSON();
      const newContent = JSON.stringify(json);
      const prevContent = localStorage.getItem('workspace');

      const newMD5 = md5(newContent);
      const prevMD5 = md5(prevContent || '');

      if (newMD5 !== prevMD5) {
        console.log('Saving', json);
        localStorage.setItem('workspace', newContent);
      }
    } catch(error) {
      console.error('Erorr saving to local storage', error);
    }
  }

  static async load() {
    console.log('Loading workspace from local storage...');

    const previousWorkspace = localStorage.getItem("workspace");

    let workspace;

    if (previousWorkspace && previousWorkspace.length > 1) {
      workspace = Workspace.fromJSON(JSON.parse(previousWorkspace));
    } else {
      workspace = Workspace.default();
    }

    globalState.workspace = workspace;

    return workspace;
  }

  //---------------------------------------------------------------------------
  // Static
  //---------------------------------------------------------------------------
  static default() {
    const workspace = new Workspace('Default Workspace');
    const taskBoard = workspace.createBoard('Default TaskBoard');

    taskBoard.taskNodeRoot = DEFAULT_TASK_TREE_ROOT;
    taskBoard.taskNodeRoot._taskBoard = taskBoard;

    workspace.setCurBoard(taskBoard);

    return workspace;
  }
}

<template>
  <div class="drag-source">
    <div :class="dragSourceContentClass" ref="contents">
      <slot />
    </div>
  </div>
</template>

<script>
import './DragSource.scss';

export default {
  props: {
    data: {
      type: [Object, String],
      default: undefined
    }
  },
  data() {
    return {
      selfIsDragging: false
    }
  },
  computed: {
    dragSourceContentClass() {
      return this.selfIsDragging ? 'drag-source-content selfIsDragging' : 'drag-source-content';
    }
  },
  methods: {
    stopDrag() {
      this.selfIsDragging = false;
    },
    startDrag() {
      this.selfIsDragging = true;

      const style = window.getComputedStyle(this.$refs.contents);

      const mouse = this.$store.state.dragAndDrop.mouse.cur;
      const rect = this.$refs.contents.getBoundingClientRect();
      const xOffset = mouse.clientX - rect.left; //x position within the element.
      const yOffset = mouse.clientY - rect.top;  //y position within the element.

      this.$emit('dragStart');

      const dragDOMNode = this.$refs.contents.cloneNode(true);

      this.$store.commit('dragAndDrop/acceptDrag', {
        dragDOMNode,
        dragDOMNodeSize: {
          width: parseFloat(style.width),
          height: parseFloat(style.height)
        },
        dragOffset: {
          x: -xOffset,
          y: -yOffset
        }
      });
    }
  },
  watch: {
    '$store.state.dragAndDrop.dragData': function(cur) {
      if (!cur || cur !== this.data) {
        this.stopDrag();
      } else if (cur === this.data) {
        this.startDrag();
      }
    }
  }
}
</script>

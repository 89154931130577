<template>
  <div :class="workspaceClass">
    <div class="task-nodes-container" v-if="hasRenderedTasks()">
      <task-node :taskNode="taskNodeRoot" v-if="taskNodeRoot" />
    </div>
    <div class="tasks-loading-container" v-else-if="!taskNodeRoot">
      Loading...
    </div>
    <div class="task-nodes-empty-container" v-else>
      <h1>No tasks. You're free!</h1>
      <button @click.prevent="addFirstTask_clickHandler" class="highlight large"><i class="fas fa-plus" /> Task</button>
    </div>
    <div class="chart-modal" v-if="showChartsModal()" @scroll.prevent="() => {}" @wheel.prevent="() => {}">
      <button class="close" @click.prevent="close_clickHandler"><i class="fas fa-times-circle" /></button>
      <task-charts />
    </div>
  </div>
</template>
<script>
import Workspace from '~/model/workspace/Workspace';
import DragDropContainer from '~/components/dragAndDrop/DragDropContainer';
import User from '~/model/user/User';
import TaskNode from '~/components/TaskNode';
import TaskCharts from '~/components/TaskCharts';
import { globalState } from '~/store/global';

import './Workspace.scss';

export default {
  components: {
    TaskNode,
    TaskCharts,
    DragDropContainer
  },
  data() {
    return {
      taskNodeRoot: undefined,
      user: undefined,
      searchText: ''
    }
  },
  mounted() {
    setTimeout(() => {
      Workspace.load();
      User.load();
    }, 100);

    window.addEventListener('keydown', this.window_keyDownHandler);
    window.addEventListener('click', this.window_clickHandler);

    this.interval = setInterval(this.globalIntervalHandler, 1000);

    this.globalIntervalHandler();
  },
  unmounted() {
    window.removeEventListener('keydown', this.window_keyDownHandler);
    window.removeEventListener('click', this.window_clickHandler);

    clearInterval(this.interval);
  },
  computed: {
    workspaceClass() {
      return 'workspace' + (this.isLoaded ? ' show' : '');
    },
    isLoaded() {
      return this.taskNodeRoot && this.user;
    }
  },
  methods: {
    hasRenderedTasks() {
      return this.taskNodeRoot && this.taskNodeRoot.renderedTasks.length > 0;
    },
    globalIntervalHandler() {
      this.user = globalState.user;
      this.taskNodeRoot = globalState.workspace ? globalState.workspace.curTaskBoard.taskNodeRoot : undefined;

      if (this.isLoaded) {
        globalState.workspace.save();
        globalState.user.save();
      }

      this.$store.commit('global/update');
    },
    addFirstTask_clickHandler(event) {
      event.stopPropagation();
      const task = globalState.workspace.curTaskBoard.taskNodeRoot.addTask();
      globalState.workspace.curTaskBoard.edit(task);
    },
    showChartsModal() {
      return globalState.workspace ? globalState.workspace.ui.showChartsModal : false;
    },
    close_clickHandler() {
      globalState.workspace.ui.showChartsModal = false;
      this.$forceUpdate();
    },
    window_clickHandler(event) {
      if (event.target.tagName.toLowerCase() !== 'input') {
        if (globalState.workspace.curTaskBoard.editingTaskNode && globalState.workspace.curTaskBoard.editingTaskNode.isNew) {
          globalState.workspace.curTaskBoard.editingTaskNode.parent.deleteTask(globalState.workspace.curTaskBoard.editingTaskNode);
        }

        globalState.workspace.curTaskBoard.selTaskNode = undefined;
        globalState.workspace.curTaskBoard.editingTaskNode = undefined;
      }
    },
    window_keyDownHandler(event) {
      const { curTaskBoard } = globalState.workspace;

      if (event.keyCode === 32 && !curTaskBoard.editingTaskNode) {
        if (['INPUT', 'TEXTAREA'].indexOf(event.target.nodeName) !== -1) {
          return;
        }
        event.stopPropagation();
        event.preventDefault();
        curTaskBoard.toggleSelected();
      }

      switch (event.key) {
        case 'Enter':
          event.stopPropagation();
          event.preventDefault();
          curTaskBoard.enter_handler();
          break;
        case 'Escape':
          event.stopPropagation();
          event.preventDefault();
          curTaskBoard.escape_handler();
          break;
        case 'Tab':
          if (['INPUT', 'TEXTAREA'].indexOf(event.target.nodeName) !== -1) {
            return;
          }
          event.stopPropagation();
          event.preventDefault();
          curTaskBoard.tab_handler(event.shiftKey);
          break;
        case 'ArrowDown':
          event.stopPropagation();
          event.preventDefault();
          curTaskBoard.arrowDown_handler(event.shiftKey);
          break;
        case 'ArrowUp':
          event.stopPropagation();
          event.preventDefault();
          curTaskBoard.arrowUp_handler(event.shiftKey);
          break;
        case 'ArrowLeft':
          if (!curTaskBoard.editingTaskNode) {
            event.stopPropagation();
            event.preventDefault();
            curTaskBoard.arrowLeft_handler();
          }
          break;
        case 'ArrowRight':
          if (!curTaskBoard.editingTaskNode) {
            event.stopPropagation();
            event.preventDefault();
            curTaskBoard.arrowRight_handler();
          }
          break;
      }
    }
  },
  watch: {
    '$store.state.global.update': function(cur, prev) {
      this.$forceUpdate();
    }
  }
};
</script>

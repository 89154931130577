<template>
  <div class="task-charts">
    <h1>{{title()}}</h1>
    <apexchart width="100%" height="90%" type="treemap" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>
<script>
import { globalState } from '~/store/global';

import { msToTime } from '~/utils/FormatUtil'
import './TaskCharts.scss';

export default {
  data() {
    return {
      chartOptions: {
        legend: {
          show: true
        },
        chart: {
          type: 'treemap'
        },
        dataLabels: {
          enabled: true
        },
        tooltip: {
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return msToTime(value);
            }
          }
        }
      },
      series: []
    }
  },
  mounted() {
    setTimeout(() => {
      setInterval(() => this.buildTreeMap(), 10000);
      this.buildTreeMap();
    }, 100);
  },
  methods: {
    title() {
      if (globalState.workspace.curTaskBoard.selTaskNode) {
        return globalState.workspace.curTaskBoard.selTaskNode.name;
      }

      return 'Workspace';
    },
    buildTreeMap() {
      const series = [];

      let rootNode = globalState.workspace.curTaskBoard.selTaskNode || globalState.workspace.curTaskBoard.taskNodeRoot;
      let { tasks } = rootNode;

      tasks = !globalState.workspace.ui.showArchived ? tasks.filter(t => !t.archived) : tasks;

      tasks.forEach(task => {
        const taskGroup = {
          name: task.name,
          data: []
        };

        if (task.tasks.length) {
          const subtasks = !globalState.workspace.ui.showArchived ? task.tasks.filter(t => !t.archived) : task.tasks;

          if (!subtasks.length) {
            taskGroup.data.push({
              x: 'No unarchived tasks',
              y: 0
            });
          }
          subtasks.forEach(subTask => {
            taskGroup.data.push({
              x: subTask.name || '[Untitled]',
              y: subTask.time || 0
            })
          })
        } else {
          taskGroup.data.push({
            x: task.name || '[Untitled]',
            y: task.time || 0
          });
        }

        series.push(taskGroup);
      });

      this.series = series;
    }
  }
};
</script>

<template>
  <div class="button-bar">
    <button v-for="button in buttons"
            :class="buttonClass(button)"
            @click="button_clickHandler(button, $event)">{{button.label}}</button>
  </div>
</template>
<script>
export default {
  props: {
    buttons: {
      type: Array,
      default: []
    }
  },
  methods: {
    buttonClass(button) {
      return {
        selected: button.selected
      }
    },
    button_clickHandler(button, event) {
      this.$emit('buttonClick', { button, event });
      this.$forceUpdate();
    }
  }
};
</script>

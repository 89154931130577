<template>
  <div :class="selfClasses" ref="self" @click="self_clickHandler">
    <div class="intervals-container">
      <div :class="intervalClass(interval)"
           v-for="interval in intervals"
           v-bind:key="interval.startDate.getTime()"
           :style="intervalStyle(interval)"
           @click="interval_clickHandler(interval, $event)">
        {{intervalName(interval)}} {{duration(interval)}}
      </div>
    </div>
    <div class="range" v-if="intervalCollection.hasIntervals">
      <div class="start" v-if="!selInterval">{{formatStartDate()}}</div>
      <div class="edit" v-if="selInterval">
        <task-node-link v-if="selInterval.taskNode" :task-node="selInterval.taskNode" />
        <span class="date-time">{{formatStartDate(selInterval.startDate)}}</span>
        to
        <span class="date-time">{{formatEndDate(selInterval.endDate)}}</span>,
        duration
        <span class="date-time">{{duration(selInterval)}}</span>
        <button v-if="deleteEnabled" @click="selIntervalDelete_clickHandler"><i class="fas fa-trash" /></button>
      </div>
      <div class="end" v-if="!selInterval">{{formatEndDate()}}</div>
    </div>
  </div>
</template>
<script>
  import './IntervalCollection.scss';
  import moment from 'moment';
  import {msToTime} from '~/utils/FormatUtil';
  import TaskNodeLink from '~/components/tasks/TaskNodeLink';

  export default {
    components: {TaskNodeLink},
    props: {
      layout: {
        type: String,
        default: 'horizontal'
      },
      minDate: {
        type: Date,
        default: undefined
      },
      maxDate: {
        type: Date,
        default: undefined
      },
      intervalCollection: {
        type: Object,
        default: undefined
      },
      dateFormat: {
        type: String,
        default: 'MMM DD HH:mm'
      },
      deleteEnabled: {
        type: Boolean,
        default: true
      },
      autoRefresh: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        selInterval: undefined,
        intervals: []
      }
    },
    computed: {
      selfClasses() {
        return { 'interval-collection': true, [this.layout]: true };
      }
    },
    mounted() {
      this.updateIntervalCollection();
    },
    methods: {
      intervalName(interval) {
        return interval.taskNode ? interval.taskNode.name : '';
      },
      formatIntervalName(interval) {
        if (interval.taskNode) {
          return interval.taskNode.name;
        }
        return ''
      },
      actualMinDate() {
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.date--;

        return this.minDate || this.intervalCollection.minDate || twentyFourHoursAgo;
      },
      actualMaxDate() {
        return this.maxDate || this.intervalCollection.maxDate || new Date();
      },
      duration(interval) {
        return msToTime(interval.time);
      },
      intervalClass(interval) {
        return {'interval': true, 'selected': interval === this.selInterval};
      },
      formatStartDate(date) {
        return moment(date || this.actualMinDate()).format(this.dateFormat);
      },
      formatEndDate(date) {
        date = date || this.actualMaxDate();

        if ((new Date().getTime()) - date.getTime() < (1000 * 60)) return 'Now';

        return moment(date).format(this.dateFormat);
      },
      rect() {
        if (this.$refs.self) {
          return this.$refs.self.getBoundingClientRect();
        } else {
          return { x: 0, y: 0, width: 0, height: 0 };
        }
      },
      dateToPosition(date, pixelDistance, inverted=false) {
        const range = this.timeRange();
        const percent = (date.getTime() - this.actualMinDate().getTime()) / range;

        const pixel = inverted ? (pixelDistance - pixelDistance * percent) : pixelDistance * percent;
        return pixel < 0 ? 0 : pixel;
      },
      timeRange() {
        return this.actualMaxDate().getTime() - this.actualMinDate().getTime();
      },
      intervalToWidth(interval) {
        const percent = interval.time / this.timeRange();

        return this.rect().width * percent;
      },
      intervalToHeight(interval) {
        const percent = interval.time / this.timeRange();

        return this.rect().height * percent;
      },
      dateToX(date) {
        return this.dateToPosition(date, this.rect().width);
      },
      dateToY(date) {
        return this.dateToPosition(date, this.rect().height, true);
      },
      intervalStyle(interval) {
        if (this.layout === 'horizontal') {
          const x = this.dateToX(interval.startDate);
          const width = this.intervalToWidth(interval);

          return {
            left: x + 'px',
            width: width + 'px',
            top: 0
          }
        } else if (this.layout === 'vertical') {
          const y = this.dateToY(interval.startDate);
          const height = this.intervalToHeight(interval);

          return {
            top: y + 'px',
            height: height + 'px',
            left: 0
          }
        }
      },
      interval_clickHandler(interval, event) {
        this.selInterval = interval === this.selInterval ? undefined : interval;
      },
      selIntervalDelete_clickHandler() {
        this.intervalCollection.deleteInterval(this.selInterval);
        this.selInterval = undefined;
      },
      self_clickHandler(event) {
        event.stopPropagation();
      },
      updateIntervalCollection() {
        if (this.selInterval) {
          const ixOfSelectedInterval = this.intervalCollection.intervals.indexOf(this.selInterval);
          if (ixOfSelectedInterval === -1) {
            this.selInterval = undefined;
          }
        }

        this.intervals = this.intervalCollection.intervals.concat();

        this.$forceUpdate();
      }
    },
    watch: {
      '$refs.self': function (cur, prev) {
        this.$forceUpdate();
      },
      '$store.state.global.update': function (cur, prev) {
        if (this.autoRefresh) {
          this.intervals = this.intervalCollection.intervals.concat();
          this.$forceUpdate();
        }
      },
      'intervalCollection': function (cur, prev) {
        this.updateIntervalCollection();
      }
    }
  };
</script>

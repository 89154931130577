<template>
  <div class="drag-source-hotspot"
       @mousedown="self_mousedownHandler"
       @mousemove="self_mousemoveHandler">
    <slot />
  </div>
</template>
<script>
  import './DragSourceHotspot.scss';
  import {isDescendentOfDomNodeNames} from '~/utils/DOMUtil';
  export default {
    props: {
      data: {
        type: [Object, String],
        default: undefined
      }
    },
    data() {
      return {
        mouseDown: false,
        selfIsDragging: false
      }
    },
    mounted() {
      window.addEventListener('mouseup', this.window_mouseUpHandler);
    },
    methods: {
      self_mousedownHandler(event) {
        if (isDescendentOfDomNodeNames(event.target, ['input', 'textarea'])){
          return;
        }
        event.stopPropagation();
        if (this.data && !isDescendentOfDomNodeNames(event.target, ['input', 'textarea'])) {
          this.mouseDown = true;
        }
      },
      self_mousemoveHandler(event) {
        if (this.data && this.mouseDown && !this.selfIsDragging) {
          this.selfIsDragging = true;

          this.$store.commit('dragAndDrop/attemptDragData', {
            dragData: this.data,
            mouse: {
              clientX: event.clientX,
              clientY: event.clientY
            }
          });
        }
      },
      window_mouseUpHandler(event) {
        this.mouseDown = false;
      }
    },
    watch: {
      '$store.state.dragAndDrop.isDragging': function(cur) {
        this.mouseDown = false;
        this.selfIsDragging = false;
      }
    }
  };
</script>

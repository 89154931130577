<template>
  <div :class="rootClasses()" ref="self">
    <drop-destination v-if="!taskNode.isRoot"
                      @drop="dropDestination_dropHandler"
                      @dropIsAccepted="dropDestination_dropIsAcceptedHandler"
                      :drop-points-allowed="dropPointsAllowed">
      <div class="task-container" @click.prevent="taskContainer_clickHandler" v-if="!taskNode.isRoot">
        <!---------------------------------------------------------------------------------------------
          Task Header
        ---------------------------------------------------------------------------------------------->
        <drag-source :data="taskNode.id"
                     @dragStart="dragSource_dragStartHandler">
          <drag-source-hotspot :data="taskNode.id">
            <div class="task-header"
                 @click="startStop_clickHandler()"
                 @mousemove="taskHeader_mousemoveHandler"
                 ref="header">
              <div class="name-container flex row center">
                <div class="expand-collapse-button-container" v-if="showExpandCollapse">
                  <button class="flat subdued" @click.prevent="expandCollapse_clickHandler">
                    <i v-if="taskNode.ui.expanded" class="fas fa-chevron-down"  />
                    <i v-else class="fas fa-chevron-right" />
                  </button>
                </div>
                <div class="name" v-if="!isEditing()" @click="name_clickHandler">
                  {{taskNode.name || '[Untitled]'}}
                </div>
                <input v-if="isEditing()"
                      tabindex="1"
                      class="name" :value="taskNode.name"
                      placeholder="Task name... (esc to cancel)"
                      ref="input"
                      @input="nameInput_changeHandler"
                      @keydown="input_keydownHandler"
                      @click.prevent="inputClickHandler" />
              </div>
              <div class="right-justified-container">
                <div class="controls" v-if="!taskNode.isRoot" @click.prevent="controls_clickHandler">
                  <button @click.prevent="editTask_clickHandler"
                          class="flat subdued"
                          v-if="!taskNode.isNew && !isEditing()">
                    <i class="fas fa-edit" />
                  </button>
                  <button @click.prevent="addTask_clickHandler"
                          class="flat subdued"
                          v-if="!taskNode.isNew && !taskNode.archived">
                    <i class="fas fa-plus" />
                  </button>
                  <div class="link" v-if="linkUrl()" @click="(event) => {event.stopPropagation()}">
                    <a :href="linkUrl()" target="_blank"><i class="link-button fas fa-link"><span>{{linkName()}}</span></i></a>
                  </div>
                </div>
                <div class="time-container">
                  <div :class="`time ${!taskNode.time ? 'not-started': ''}`">
                    {{getTime(taskNode)}}
                  </div>
                </div>
                <button @click.prevent="toggleOpen" class="flat subdued">
                  <i class="fas fa-cog" />
                </button>
              </div>
            </div>
          </drag-source-hotspot>
        </drag-source>
        <!---------------------------------------------------------------------------------------------
          Task Details
        ---------------------------------------------------------------------------------------------->
        <div :class="detailsClass" @keydown="details_keydownHandler">
          <div v-if="isEditing()">
            <textarea :style="descriptionStyle()" ref="description" tabindex="2" rows="4" v-model="taskNode.description" @change="descriptionChangeHandler" placeholder="Description... (Markdown format)" />
            <input tabindex="3" class="link" :value="linkUrl()" placeholder="Link url..." @input="link_inputHandler" />
          </div>
          <div v-else>
            <div class="description" v-html="$md.render(taskNode.description || 'No Description.')" @click.prevent="description_clickHandler" />
          </div>
          <div class="details-footer">
            <div class="details-controls">
              <div class="time-adjustments">
                Time Adjustment: {{getAdjustmentTime(taskNode)}}
              </div>
              <div class="parent-summary-container" v-if="taskNode.isParent">
                <span class="done-count">{{doneCount}}</span>/<span class="total-count">{{totalCount}} Done</span>
              </div>
              <div class="details-buttons">
                <button @click.prevent="charts_clickHandler"
                        v-if="taskNode.tasks.length && taskNode.childTime"><i class="fas fa-chart-pie" /></button>
                <button @click.prevent="archiveTask_clickHandler" class="archive"
                        v-if="!taskNode.archived"><i class="fas fa-trash" /></button>
                <button @click.prevent="restoreTask_clickHandler" class="restore"
                        v-if="taskNode.archived"><i class="fas fa-trash-restore-alt" /></button>
                <button @click.prevent="minusTime_clickHandler"
                        v-if="!taskNode.isNew && taskNode.selfTime > 0 && !taskNode.archived">-15m</button>
                <button @click.prevent="plusTime_clickHandler"
                        v-if="!taskNode.isNew && !taskNode.archived">+15m</button>
                <button @click.prevent="done_clickHandler"
                        v-if="!taskNode.isNew && !taskNode.is('done') && taskNode.time > 0 && !taskNode.archived">Done!</button>
                <button @click.prevent="reopen_clickHandler" class="reopen"
                        v-if="!taskNode.isNew && taskNode.is('done') && !taskNode.archived">Reopen</button>
                <button @click.prevent="start_clickHandler"
                        v-if="!taskNode.isNew && !taskNode.isActive && !taskNode.archived"><i class="fas fa-play"></i></button>
                <button @click.prevent="stop_clickHandler"
                        v-if="!taskNode.isNew && taskNode.isActive"><i class="fas fa-stop"></i></button>
              </div>
            </div>
            <interval-collection :interval-collection="taskNode.intervalCollection" v-if="taskNode.intervalCollection.hasIntervals" />
          </div>
        </div>
      </div>
    </drop-destination>
    <!---------------------------------------------------------------------------------------------
      Subtasks
    ---------------------------------------------------------------------------------------------->
    <div :class="subTasksContainerClasses">
      <task-node class="task" v-for="task in taskNodesRendered()" v-bind:key="task.id" :taskNode="task" />
    </div>
  </div>
</template>

<script>
  import classnames from 'classnames';
  import TaskNode from '~/model/workspace/tasks/TaskNode';
  import DragSource from '~/components/dragAndDrop/DragSource';
  import DropDestination from '~/components/dragAndDrop/DropDestination';
  import DragSourceHotspot from '~/components/dragAndDrop/DragSourceHotspot';
  import IntervalCollection from '~/components/intervals/IntervalCollection';
  import { TAG_DONE } from '~/model/workspace/tags/TagCollection';
  import { msToTime, msToAdjustmentTime } from '~/utils/FormatUtil';
  import { isInViewport } from '~/utils/DOMUtil';

  import './TaskNode.scss';

  export default {
    name: 'task-node',
    props: {
      taskNode: Object,
      classes: String
    },
    components: { DragSource, DropDestination, DragSourceHotspot, IntervalCollection },
    data() {
      return {
        opened: false,
        isDragging: false,
        dragData: undefined
      }
    },
    mounted() {
      if (this.taskNode.taskBoard.editingTaskNode === this.taskNode) {
        setTimeout(() => {
          if (this.$refs.input) {
            this.$refs.input.focus();
            this.$refs.input.scrollIntoView({block: "center", inline: "nearest"});
          } else {
            console.warn('Attempting to edit a task node, but the ref for input was undefined.');
          }
        });
      }
    },
    updated() {
      if (this.taskNode.taskBoard.tempScrollToTask === this.taskNode) {
        this.$refs.header.scrollIntoView({
          behavior: 'smooth',
          block: "center",
          inline: "nearest"
        });
        this.taskNode.taskBoard.tempScrollToTask = undefined;
      }
    },
    unmounted() {
      clearInterval(this.interval);
    },
    computed: {
      detailsClass() {
        return 'details' + (this.opened || this.isEditing() ? ' show' : '');
      },
      showExpandCollapse() {
        if (this.taskNode.isLeaf) return false;

        if (this.taskNode.taskBoard.workspace.ui.showArchived) {
          return true;
        } else {
          return this.taskNode.tasks.filter(t => !t.archived).length > 0;
        }
      },
      canStartStopTask() {
        return (this.taskNode.isLeaf && !this.taskNode.isNew) || this.taskNode.isActive;
      },
      taskIsRunning() {
        return this.taskNode.isAnyActive;
      },
      subTasksContainerClasses() {
        return classnames({
          'sub-tasks': true,
          'expanded': this.taskNode.isRoot || (this.taskNode.ui.expanded && this.taskNode.hasRenderedChildren)
        })
      },
      doneCount() {
        return this.taskNode.doneCount;
      },
      totalCount() {
        return this.taskNode.totalCount;
      },
      archivedCount() {
        return this.taskNode.archivedCount;
      },
      dropPointsAllowed() {
        return ['top', 'bottom', 'center'];
      }
    },
    methods: {
      rootClasses() {
        return classnames({
          'task-node': true,
          'active': this.taskNode.isActive,
          'done': this.taskNode.is('done'),
          'archived': this.taskNode.archived,
          'compact': this.taskNode.taskBoard.workspace.ui.showCompact,
          'selected': this.taskNode.isSelected,
          'editing': this.taskNode === this.taskNode.taskBoard.editingTaskNode,
          'parent-node': this.taskNode.tasks.length,
          'search-result': this.taskNode.isSearchResult,
          'not-search-result': !this.taskNode.isSearchResult && this.taskNode.taskBoard.ui.searchText,
          'is-dragging': this.isDragging && this.dragData === this.taskNode.id
        });
      },
      linkUrl() {
        return this.taskNode.links[0] ? this.taskNode.links[0].url : '';
      },
      linkName() {
        let link = this.taskNode.links[0] ? this.taskNode.links[0].url : '';
        if (link === '') {
          return link;
        } else {
          try {
            let url = new URL(link);
            if (url.host.includes("atlassian.net")) {
              var split = link.split("/");
              return split[split.length -1];
            } else {
              return '';
            }
          } catch (error) {
            return link;
          }
        }
      },
      descriptionStyle() {
        return {};
      },
      isEditing() {
        return this.taskNode === this.taskNode.taskBoard.editingTaskNode;
      },
      taskNodesRendered() {
        if (this.taskNode.taskBoard.workspace.ui.showArchived) {
          return this.taskNode.filteredTasks;
        } else {
          return this.taskNode.filteredTasks.filter(t => !t.archived);
        }
      },
      edit(options) {
        this.taskNode.taskBoard.edit(this.taskNode);

        setTimeout(() => {
          if (!options || options.name) {
            this.$refs.input.focus();
          } else if (options.description) {
            this.$refs.description.focus();
          }
        }, 100);
      },
      getTime(taskNode) {
        return msToTime(taskNode.time);
      },
      getAdjustmentTime(taskNode) {
        return taskNode.adjustmentTime ? msToAdjustmentTime(taskNode.adjustmentTime) : '0:00';
      },
      toggleOpen(event) {
        event.stopPropagation();
        if (this.isEditing()) {
          this.taskNode.taskBoard.unedit();
          this.opened = false;
        } else {
          this.opened = !this.opened;
        }
      },
      open() {
        this.opened = true;
      },
      close() {
        this.opened = false;
      },
      taskContainer_mouseEnterHandler() {
        clearTimeout(this.closeTimeout);
        this.openTimeout = setTimeout(this.open, 300);
      },
      taskContainer_mouseLeaveHandler() {
        clearTimeout(this.openTimeout);
        this.closeTimeout = setTimeout(this.close, 300);
      },
      description_clickHandler() {
        this.edit({ description: true });
      },
      descriptionChangeHandler() {
        this.$forceUpdate();
      },
      input_keydownHandler(event) {
        // noop... yet
      },
      details_keydownHandler(event) {
        event.stopPropagation();
      },
      taskContainer_clickHandler(event) {
        event.stopPropagation();
      },
      name_clickHandler(event) {
        event.stopPropagation();

        this.edit();
      },
      editTask_clickHandler(event) {
        event.stopPropagation();

        this.edit();
      },
      charts_clickHandler(event) {
        this.taskNode.taskBoard.workspace.ui.showChartsModal = true;
      },
      start_clickHandler(event) {
        this.taskNode.root.stopAll();
        this.taskNode.start();
      },
      stop_clickHandler(event) {
        this.taskNode.stop();
      },
      addTask_clickHandler(event) {
        event.stopPropagation();
        const newTask = new TaskNode();
        this.taskNode.ui.expanded = true;
        this.taskNode.addTask(newTask);
        this.taskNode.taskBoard.editingTaskNode = this.taskNode.taskBoard.selTaskNode = newTask;
      },
      archiveTask_clickHandler(event) {
        event.stopPropagation();
        this.taskNode.archive();
      },
      restoreTask_clickHandler(event) {
        event.stopPropagation()
        this.taskNode.restore();
      },
      nameInput_changeHandler(event) {
        this.taskNode.name = event.target.value;
      },
      startStop_clickHandler() {
        if (this.taskNode.archived) {
          return;
        }

        if (this.taskNode.isActive) {
          this.taskNode.root.stopAll();
        } else {
          this.taskNode.root.stopAll();
          this.taskNode.start();
        }
      },
      stopAll_clickHandler() {
        this.taskNode.stopAll();
      },
      inputClickHandler(event) {
        event.stopPropagation();
      },
      done_clickHandler() {
        this.taskNode.addTag(TAG_DONE);
        this.taskNode.stop();
        this.opened = false;
      },
      reopen_clickHandler() {
        this.taskNode.removeTag(TAG_DONE);
      },
      controls_clickHandler(event) {
        event.stopPropagation();
      },
      expandCollapse_clickHandler(event) {
        event.stopPropagation();
        this.taskNode.ui.expanded = !this.taskNode.ui.expanded;

        if (!this.taskNode.ui.expanded) {
          this.opened = false;
        }
      },
      minusTime_clickHandler(event) {
        const FIFTEEN_MINUTES = 15 * 60 * 1000;

        this.taskNode.adjust(-FIFTEEN_MINUTES);
      },
      plusTime_clickHandler(event) {
        const FIFTEEN_MINUTES = 15 * 60 * 1000;

        this.taskNode.adjust(FIFTEEN_MINUTES);
      },
      taskHeader_mousemoveHandler() {
        if (!this.taskNode.taskBoard.editingTaskNode && !this.$store.state.dragAndDrop.isDragging) {
          this.taskNode.taskBoard.selTaskNode = this.taskNode;
        }
      },
      link_inputHandler(event) {
        this.taskNode.links[0] = this.taskNode.links[0] || {
          url: ''
        };

        this.taskNode.links[0].url = event.target.value;
      },
      dropDestination_dropIsAcceptedHandler({ event, dropPoint, data, callback }) {
        // we accept drops that are are not our own node and not a descendent of that node
        const task = this.taskNode.root.getTaskById(data);

        callback(task !== this.taskNode && !task.isTaskDescendent(this.taskNode))
      },
      dropDestination_dropHandler({ dropPoint, data }) {
        const taskNodeToMove = this.taskNode.root.getTaskById(data);

        if (dropPoint.name === 'top') {
          this.taskNode.taskBoard.moveTaskNodeBefore(taskNodeToMove, this.taskNode);
        }

        if (dropPoint.name === 'bottom') {
          if (this.taskNode.ui.expanded && this.taskNode.hasRenderedChildren) {
            this.taskNode.taskBoard.moveTaskNodeAsFirstChildOf(taskNodeToMove, this.taskNode);
          } else {
            this.taskNode.taskBoard.moveTaskNodeAfter(taskNodeToMove, this.taskNode);
          }
        }

        if (dropPoint.name === 'center') {
          this.taskNode.ui.expanded = true;
          this.taskNode.taskBoard.moveTaskNodeAsFirstChildOf(taskNodeToMove, this.taskNode);
        }
      },
      dragSource_dragStartHandler() {
        this.taskNode.taskBoard.selTaskNode = undefined;
      }
    },
    watch: {
      '$store.state.global.update': function (cur, prev) {
        this.$forceUpdate();
      },
      '$store.state.dragAndDrop.isDragging': function(cur) {
        this.isDragging = cur;
      },
      '$store.state.dragAndDrop.dragData': function(cur) {
        this.dragData = cur;
      },
      'taskNode.taskBoard.editingTaskNode': function(cur, prev) {
        if (cur === this.taskNode) {
          setTimeout(() => {
            this.$refs.input.focus();
          });
        }
      },
      'taskNode.taskBoard.selTaskNode': function(cur, prev) {
        if (cur === this.taskNode) {
          if (this.$refs.header && !isInViewport(this.$refs.header)) {
            this.$refs.header.scrollIntoView({
              block: "center",
              inline: "nearest"
            });
          }
        }
      },
      'taskNode.taskBoard.ui.searchText': function(cur, prev) {
        this.$forceUpdate();
      }
    }
  };
</script>

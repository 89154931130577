import TaskNode from './tasks/TaskNode';
import { randomId } from '~/utils/IdUtil';
import TrieSearch from 'trie-search';
import IntervalCollection from '~/model/workspace/intervals/IntervalCollection';

const default_ui = {
  searchText: undefined
};

export default class TaskBoard {
  //---------------------------------------------------------------------------
  // Constructor
  //---------------------------------------------------------------------------
  constructor(name, id = undefined, workspace) {
    this.name = name;
    this.id = id || randomId();
    this.ui = default_ui;
    this.workspace = workspace;
    this.taskNodeRoot = new TaskNode('root', undefined, [], undefined, undefined, this);

    this.editingTaskNode = undefined;
    this.selTaskNode = undefined;

    this.tempScrollToTask = undefined;
  }

  //---------------------------------------------------------------------------
  // Properties
  //---------------------------------------------------------------------------
  get time() {
    return this.taskNodeRoot.time;
  }

  //---------------------------------------------------------------------------
  // Methods
  //---------------------------------------------------------------------------
  reset() {
    this.taskNodeRoot = new TaskNode('root', undefined, [], undefined, undefined, this);
    this.taskNodeRoot._taskBoard = this;
  }

  edit(taskNode) {
    this.editingTaskNode = taskNode;
    this.selTaskNode = taskNode;
  }

  unedit() {
    this.editingTaskNode = undefined;
  }

  select(taskNode) {
    this.selTaskNode = taskNode;
  }

  scrollTo(taskNode) {
    this.tempScrollToTask = taskNode;
  }

  collapseAll() {
    this.taskNodeRoot.forEach(task => task.collapse());
  }

  search(value) {
    if (value) {
      this.collapseAll();
    }

    this.trie = new TrieSearch(['name'], {
      splitOnRegEx: /[\s\/\(\)]/ // Split on '/' and '(' and ')' and whitespace
    });

    this.taskNodeRoot.forEach(taskNode => {
      this.trie.add(taskNode);
    });

    this.ui.searchText = value;

    this.searchFoundTasks = this.trie.get(this.ui.searchText);

    this.searchFoundTasks.forEach(task => task.expandParents());
  }

  toggleSelected() {
    if (this.selTaskNode) {
      if (this.selTaskNode.isActive) {
        this.selTaskNode.stop();
      } else {
        this.taskNodeRoot.stopAll();
        this.selTaskNode.start();
      }
    }
  }

  moveTaskNodeAfter(taskNodeToMove, taskNodeTarget) {
    // Remove from existing parent
    taskNodeToMove.parent.deleteTask(taskNodeToMove);

    // Insert after taskNodeTarget
    taskNodeTarget.parent.addTaskAfter(taskNodeToMove, taskNodeTarget);
  }

  moveTaskNodeBefore(taskNodeToMove, taskNodeTarget) {
    // Remove from existing parent
    taskNodeToMove.parent.deleteTask(taskNodeToMove);

    // Insert after taskNodeTarget
    taskNodeTarget.parent.addTaskBefore(taskNodeToMove, taskNodeTarget);
  }

  moveTaskNodeAsFirstChildOf(taskNodeToMove, taskNodeTarget) {
    // Remove from existing parent
    taskNodeToMove.parent.deleteTask(taskNodeToMove);

    // Insert as first child
    taskNodeTarget.addTask(taskNodeToMove, 0);
  }

  buildGlobalIntervalCollection(startDate, endDate) {
    if (!this.taskNodeRoot) {
      return undefined;
    }

    let allIntervals = [];

    this.taskNodeRoot.forEach(t => {
      let taskIntervals = t.intervalCollection.intervals.filter(i => i.isWithin(startDate, endDate) && i.time > 60000);
      taskIntervals = taskIntervals.map(i => {
        i = i.clone();
        i.taskNode = t;
        return i;
      });
      allIntervals = allIntervals.concat(taskIntervals);
    });

    allIntervals.sort((i1, i2) => {
      return i1.startDate.getTime() - i2.startDate.getTime();
    });
    return new IntervalCollection(allIntervals);
  }

  //---------------------------------------------------------------------------
  // Events
  //---------------------------------------------------------------------------
  enter_handler() {
    if (this.editingTaskNode && !this.editingTaskNode.isNew) {
      const newTaskNode = new TaskNode();
      this.editingTaskNode.parent.addTaskAfter(newTaskNode, this.editingTaskNode);
      this.edit(newTaskNode);
    } else if (this.selTaskNode) {
      this.edit(this.selTaskNode);
    }
  }

  escape_handler() {
    if (this.editingTaskNode && this.editingTaskNode.isNew) {
      this.editingTaskNode.parent.deleteTask(this.editingTaskNode);
    }

    this.editingTaskNode = undefined;
  }

  arrowDown_handler(shiftKey) {
    this.editingTaskNode = undefined;

    if (!shiftKey) {
      if (!this.selTaskNode) {
        if (this.workspace.ui.showArchived) {
          this.selTaskNode = this.taskNodeRoot.tasks[0];
        } else {
          this.selTaskNode = this.taskNodeRoot.tasks.find(t => !t.archived);
        }
      } else {
        this.selTaskNode = this.selTaskNode.getNextVerticalNode(this.workspace.ui.showArchived);
      }
    } else if (this.selTaskNode) {
      const tasks = this.selTaskNode.parent.tasks.concat();

      let ix = tasks.indexOf(this.selTaskNode);

      if (ix < tasks.length - 1) {
        const indexToDelete = ix;

        if (!this.workspace.ui.showArchived) {
          while (ix < tasks.length && tasks[ix + 1].archived) {
            ix++;
          }

          tasks.splice(indexToDelete, 1);
          tasks.splice(ix + 1, 0, this.selTaskNode);
        } else {
          tasks.splice(indexToDelete, 1);
          tasks.splice(ix + 1, 0, this.selTaskNode);
        }

        this.selTaskNode.parent.tasks = tasks;
        this.tempScrollToTask = this.selTaskNode;
      }
    }
  }

  arrowUp_handler(shiftKey) {
    this.editingTaskNode = undefined;

    if (!shiftKey) {
      if (!this.selTaskNode) {
        if (this.workspace.ui.showArchived) {
          this.selTaskNode = this.taskNodeRoot.tasks[this.taskNodeRoot.tasks.length - 1];
        } else {
          this.selTaskNode = this.taskNodeRoot.tasks.concat().reverse().find(t => !t.archived);
        }
      } else {
        this.selTaskNode = this.selTaskNode.getPrevVerticalNode(this.workspace.ui.showArchived);
      }
    } else if (this.selTaskNode) {
      const tasks = this.selTaskNode.parent.tasks.concat();

      let ix = tasks.indexOf(this.selTaskNode);
``
      if (ix > 0) {
        const indexToDelete = ix;
        if (!this.workspace.ui.showArchived) {
          // Find previous non-archived item, and place after that
          while (ix > 1 && tasks[ix - 1].archived) {
            ix--;
          }
          tasks.splice(indexToDelete, 1);
          tasks.splice(ix - 1, 0, this.selTaskNode);
        } else {
          tasks.splice(indexToDelete, 1);
          tasks.splice(ix - 1, 0, this.selTaskNode);
        }

        this.selTaskNode.parent.tasks = tasks;
        this.tempScrollToTask = this.selTaskNode;
      }
    }
  }

  tab_handler(shiftKey) {
    if (this.selTaskNode) {
      if (!shiftKey) {
        // Take the current selected task, and move it to be the child of its prev sibling
        let prevIx;

        prevIx = this.selTaskNode.parent.tasks.indexOf(this.selTaskNode) - 1;

        if (prevIx >= 0) {
          let prevSibling = this.selTaskNode.parent.tasks[prevIx];

          while (prevSibling.archived) {
            prevIx--;
            if (prevIx < 0) return;
            prevSibling = this.selTaskNode.parent.tasks[prevIx];
          }

          // Remove from current position
          this.selTaskNode.parent.deleteTask(this.selTaskNode);

          // Insert as child of prevSibling
          prevSibling.addTask(this.selTaskNode);

          // Make sure the one we insert into gets expanded
          prevSibling.ui.expanded = true;
        }
      } else {
        if (!this.selTaskNode.parent.isRoot) {
          // We want to move it as the next sibling of our parent
          const parentIx = this.selTaskNode.parent.parent.tasks.indexOf(this.selTaskNode.parent);
          const nextSiblingIx = parentIx + 1;

          // Remove from current position
          this.selTaskNode.parent.deleteTask(this.selTaskNode);

          // Insert at the index nextSiblingIx into the parent's parent
          this.selTaskNode.parent.parent.addTask(this.selTaskNode, nextSiblingIx);
        }
      }
    }
  }

  arrowLeft_handler() {
    if (this.selTaskNode && !this.selTaskNode.isRoot && this.selTaskNode.tasks.length) {
      this.selTaskNode.ui.expanded = false;
    }
  }

  arrowRight_handler() {
    if (this.selTaskNode) {
      this.selTaskNode.ui.expanded = true;
    }
  }

  //---------------------------------------------------------------------------
  // Serialization
  //---------------------------------------------------------------------------
  static fromJSON(json) {
    const taskBoard = new TaskBoard(json.name, json.id);

    taskBoard.ui = json.ui;
    taskBoard.taskNodeRoot = TaskNode.fromJSON(json.taskNodeRoot);
    taskBoard.taskNodeRoot._taskBoard = taskBoard;

    return taskBoard;
  }

  toJSON() {
    return {
      name: this.name,
      id: this.id,
      ui: this.ui,
      taskNodeRoot: this.taskNodeRoot.toJSON()
    }
  }
}

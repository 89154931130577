<template>
  <div class="home flex row" :style="backgroundStyle">
    <div class="interval-container">
      <timeline />
    </div>
    <div class="workspace-container">
      <workspace />
    </div>
  </div>
</template>

<script>
import Workspace from '~/components/Workspace';
import './index.scss';
import { globalState } from '~/store/global';
import Timeline from '~/components/Timeline';

export default {
  components: {
    Timeline,
    Workspace
  },
  data() {
    return {
      backgroundUrl: ''
    }
  },
  head () {
    return {
      title:'Time Thang',
      meta: [
        { hid: 'description', name: 'description', content: 'Keep track of how long you spend on things.' }
      ]
    }
  },
  computed: {
    backgroundStyle() {
      return {
        'background-image': `url(${this.backgroundUrl})`,
        'background-size': 'cover',
        'background-position': 'center center'
      }
    }
  },
  watch: {
    '$store.state.global.update': function(cur, prev) {
      this.backgroundUrl = globalState.workspace ? globalState.workspace.ui.backgroundUrl : '';
    }
  }
}
</script>

<template>
  <div class="timeline">
    <div class="timeline-header">
      <h3>Timeline ({{totalTime}})</h3>
      <button-bar :buttons="buttons" @buttonClick="buttonBar_buttonClickHandler" />
    </div>
    <interval-collection layout="horizontal"
                         v-if="globalIntervalCollection"
                         :delete-enabled="false"
                         :auto-refresh="false"
                         :interval-collection="globalIntervalCollection"
                         :min-date="selMinDate"
                         :max-date="selMaxDate" />
  </div>
</template>
<script>
import { globalState } from '~/store/global';
import IntervalCollection from '~/components/intervals/IntervalCollection';
import ButtonBar from '~/components/ButtonBar';
import './Timeline.scss';
import {msToTime} from '~/utils/FormatUtil';

export default {
  components: {ButtonBar, IntervalCollection},
  props: {
    taskBoard: {
      type: Object,
      default: undefined
    }
  },
  data() {
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return {
      globalIntervalCollection: undefined,
      selMinDate: yesterday,
      selMaxDate: now,
      buttons: [
        { label: 'Last Hour', range: 'lastHour' },
        { label: 'Today', range: 'today' },
        { label: 'Yesterday', range: 'yesterday'},
        { label: 'Week', range: 'week' },
        { label: 'Month', range: 'month' }
      ]
    }
  },
  computed: {
    totalTime() {
      return this.globalIntervalCollection ? msToTime(this.globalIntervalCollection.time)  : '0:00';
    }
  },
  methods: {
    buttonBar_buttonClickHandler({ button, event }) {
      this.selectRange(button.range)
    },
    selectRange(range) {
      switch (range) {
        case 'lastHour':
          const beginningOfHour = new Date();
          beginningOfHour.setHours(beginningOfHour.getHours() - 1);
          this.selMaxDate = new Date();
          this.selMinDate = beginningOfHour;
          break;
        case 'today':
          const beginningOfToday = new Date();
          beginningOfToday.setHours(0);
          beginningOfToday.setMinutes(0);
          beginningOfToday.setSeconds(0);
          this.selMaxDate = new Date();
          this.selMinDate = beginningOfToday;
          break;
        case 'yesterday':
          const yesterdayEnd = new Date();
          yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
          yesterdayEnd.setHours(23);
          yesterdayEnd.setMinutes(59);
          yesterdayEnd.setSeconds(59);

          const yesterdayBegin = new Date();
          yesterdayBegin.setDate(yesterdayBegin.getDate() - 1);
          yesterdayBegin.setHours(0);
          yesterdayBegin.setMinutes(0);
          yesterdayBegin.setSeconds(0);
          this.selMaxDate = yesterdayEnd;
          this.selMinDate = yesterdayBegin;
          break;
        case 'week':
          function startOfWeek(date) {
            const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
            return new Date(date.setDate(diff));
          }
          const weekBegin = startOfWeek(new Date());
          weekBegin.setHours(0);
          weekBegin.setMinutes(0);
          weekBegin.setSeconds(0);

          this.selMaxDate = new Date();
          this.selMinDate = weekBegin;
          break;
        case 'month':
          const monthBegin =new Date();
          monthBegin.setDate(0);
          monthBegin.setHours(0);
          monthBegin.setMinutes(0);
          monthBegin.setSeconds(0);

          this.selMaxDate = new Date();
          this.selMinDate = monthBegin;
          break;
      }

      this.buttons.forEach(button => button.selected = button.range === range);

      if (globalState.workspace && globalState.workspace.curTaskBoard) {
        this.globalIntervalCollection = globalState.workspace.curTaskBoard.buildGlobalIntervalCollection(this.selMinDate, this.selMaxDate);

        this.$forceUpdate();
      }
    }
  }
};
</script>
